<template>
  <v-card class="pa-2">
    <v-toolbar color="white" flat>
      <v-toolbar-title class="headline font-weight-light text-capitalize">
        {{ $t('users.editProfile') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-form ref="updateProfileForm">
        <v-text-field
          solo
          v-model="user.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
          prepend-inner-icon="mdi-account"
        ></v-text-field>
        <v-text-field
          solo
          v-model="user.imageUrl"
          :label="$t('common.imageUrl')"
          prepend-inner-icon="mdi-image"
        ></v-text-field>

        <v-select
          solo
          :items="languages"
          v-model="user.lang"
          item-value="value"
          item-text="key"
          prepend-inner-icon="mdi-earth"
        ></v-select>

        <div class="text-right">
          <v-btn dark color="primary" @click="save">
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_PROFILE } from '../store/types';
import validators from '../../../core/validators';
import languages from '../../../enums/languages';

export default {
  name: 'update-profile',
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      languages: [
        {
          key: this.$i18n.t('languages.en'),
          value: languages.en
        },
        {
          key: this.$i18n.t('languages.bg'),
          value: languages.bg
        }
      ],
      user: {
        name: '',
        imageUrl: '',
        lang: languages.en
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    this.user.name = this.currentUser.name;
    this.user.imageUrl = this.currentUser.imageUrl;
    this.user.lang = this.currentUser.lang;
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
    },
    async save() {
      if (!this.$refs.updateProfileForm.validate()) {
        return;
      }

      await this.$store.dispatch(UPDATE_PROFILE, this.user);
      this.setLanguage(this.user.lang);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('users.editProfileSuccess')
      });
    }
  }
};
</script>
