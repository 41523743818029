<template>
  <v-card class="pa-2">
    <v-toolbar color="white" flat>
      <v-toolbar-title class="headline font-weight-light text-capitalize">
        {{ $t('users.changePassword') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-form ref="changePasswordForm">
        <v-text-field
          solo
          v-model="passwordData.oldPassword"
          :rules="[rules.required]"
          type="password"
          :label="$t('auth.oldPassword')"
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
        <v-text-field
          solo
          v-model="passwordData.newPassword"
          :rules="[rules.required, rules.passwordMinLength]"
          type="password"
          :label="$t('auth.newPassword')"
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
        <v-text-field
          solo
          v-model="passwordData.confirmNewPassword"
          :rules="[
            rules.required,
            rules.equalWithPassword(passwordData.newPassword)
          ]"
          type="password"
          :label="$t('auth.confirmNewPassword')"
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
        <div class="text-right">
          <v-btn dark color="primary" @click="save">
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { CHANGE_PASSWORD } from '../store/types';
import validators from '../../../core/validators';

const PASSWORD_MIN_LENGTH = 6;

export default {
  name: 'change-password',
  data() {
    return {
      passwordData: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        equalWithPassword: prop =>
          validators.equal(prop, this.$i18n.t('auth.passwordMismatch')),
        passwordMinLength: validators.min(
          PASSWORD_MIN_LENGTH,
          this.$i18n.t('common.minLength', {
            value: PASSWORD_MIN_LENGTH
          })
        )
      }
    };
  },
  methods: {
    async save() {
      if (!this.$refs.changePasswordForm.validate()) {
        return;
      }

      await this.$store.dispatch(CHANGE_PASSWORD, this.passwordData);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('auth.passwordChangedSuccessfully')
      });
    }
  }
};
</script>
