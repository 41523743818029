<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" sm="12">
        <update-profile></update-profile>
      </v-col>
      <v-col md="6" sm="12">
        <change-password></change-password>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UpdateProfile from '../components/UpdateProfile';
import ChangePassword from '../components/ChangePassword';

export default {
  name: 'settings',
  components: {
    UpdateProfile,
    ChangePassword
  }
};
</script>
